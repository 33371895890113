.ReplyMessage {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--background);
  cursor: pointer;
  .Field-Label {
    font-size: 18px;
    font-weight: 500; } }

@media screen and ( max-width: 576px ) {
  .ReplyMessage {
    padding: 0;
    background: none;
    .Field-Container {
      max-width: calc(100vw - 64px);
      width: 100%; } } }
