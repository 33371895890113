.Home {
  width: 100%;
  padding-left: 96px; }
.Routes {
  width: 100%;
  padding: 16px 32px 16px 0px; }

@media screen and ( max-width: 576px ) {
  .Home {
    padding-left: 0; }
  .Routes {
    padding: 16px; } }
