.OptionsManager-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  padding: 32px 0px;
  .Tech {
    display: grid;
    grid-template-columns: repeat(2, max-content) 1fr;
    grid-gap: 32px; }
  .Title {
    font-size: 18px;
    font-weight: 500; }
  .MainInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    .Fieldset {
      display: grid;
      grid-template-columns: 1fr 16px;
      width: 100%;
      grid-gap: 16px;
      .Fields {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-gap: 16px; } } }

  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }

@media screen and ( max-width: 576px) {
  .OptionsManager-Content {
    .Tech {
      grid-template-columns: 1fr; }
    .MainInfo {
      grid-template-columns: 1fr;
      grid-gap: 48px; } } }
