.InfoLine {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 24px;
  color: var(--black);
  .Icon {
    margin-top: 2px;
    font-size: 18px;
    margin-right: 8px;
    color: var(--accent); }
  .Bolder {
    margin-right: 8px;
    font-weight: 700;
    color: var(--darkGray); } }
