.CarsManager-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  padding: 32px 0px;
  .Title {
    font-size: 18px;
    font-weight: 500; }
  .MainInfo {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 24px; }
  .PriceInfo {
    .Fieldset {
      display: grid;
      grid-template-columns: 1fr 16px;
      width: 100%;
      grid-gap: 16px;
      .Fields {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-gap: 16px; } } }

  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }

@media screen and ( max-width: 576px ) {
  .CarsManager-Content {
    .MainInfo {
      grid-template-columns: 1fr;
      grid-gap: 48px; }
    .PriceInfo {
      .Fieldset {

        .Fields {
          grid-gap: 48px;
          grid-template-columns: 1fr;
          padding-bottom: 16px;
          border-bottom: 1px var(--darkGray) solid; } } } } }
