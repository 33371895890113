.Pagination-Container {
  display: flex;
  margin: 24px 0px;
  li {
    font-size: 12px;
    font-weight: 500;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    cursor: pointer;
    a {
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--darkGray);
      height: 100%;
      &:hover {
        color: var(--accent) !important; } }
    &:las-child {
      margin-right: 0; } }

  .disabled * {
    cursor: default;
    color: var(--darkGray) !important; }

  .active {
    background-color: #fff;
    border-radius: 100%;
    box-shadow: var(--boxShadow);
    a {
      color: var(--accent) !important;
      text-decoration: none; } }

  .previous, .next {
    svg {
      color: #000; }
    &:hover {
      svg {
        color: var(--accent); } } } }
