.DatePicker {
  .react-datepicker__month-container {
    &:last-child {
      border-left: 1px var(--lightGray) solid; } }
  .react-datepicker {
    border: 0;
    border-radius: 8px;
    font-size: 12px; }
  .react-datepicker__header {
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 0px var(--lightGray) solid; }
  .react-datepicker__current-month {
    font-size: 14px;
    // color: var(--blackColor)
    height: 24px;
    text-align: center; }
  .react-datepicker__day-name {
    font-size: 10px;
    font-weight: 500;
    // color: var(--descriptionDark)
    margin: 0;
    width: 32px;
    line-height: 32px; }
  .react-datepicker__day {
    color: #000;
    border-radius: 100%;
    font-weight: 400;
    margin: 0;
    width: 32px;
    line-height: 32px;
    &:hover {
      background: var(--accent);
      color: #fff !important; } }
  .react-datepicker__day--keyboard-selected {
    color: #fff !important;
    background: var(--accent) !important; }
  .react-datepicker__day--today {
    background: var(--accent) !important;
    color: #fff;
    &:hover {
      color: #fff;
      background: var(--color-primary-10) !important; } }
  .react-datepicker__day--disabled, .react-datepicker_month--disabled {
    color: var(--pushedButton); }
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range,
  .react-datepicker__day--in-selecting-range {
    background: var(--lightGray);
    color: var(--accent) !important;
    border-radius: 0; }
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {}
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item:hover,
  .react-datepicker__time-list-item--selected {
    background: var(--accent) !important;
    color: #fff !important;
    box-shadow: var(--boxShadow); }
  .react-datepicker__month-text {
    &:hover {
      background: var(--color-primary-10);
      color: #fff !important;
      box-shadow: var(--whiteBoxShadow); } }
  .react-datepicker__day--range-end, {
    background-color: var(--accent) !important;
    color: #fff !important; }

  .react-datepicker-time__header {
    color: #fff;
    position: relative;
    font-size: 0;
    &:before {
      width: 100%;
      font-size: 14px;
      color: var(--blackColor);
      content: 'Время';
      display: block; } }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 120px; }
  .react-datepicker__time-container {
    width: 102px;
    padding: 0px 8px;
    width: auto;
    border-left: 1px var(--lightGray) solid; }
  .react-datepicker__navigation {
    display: inline-block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: transparent;
    text-indent: -9999px;
    border: 0;
    border-top: 2px solid var(--darkGray);
    border-left: 2px solid var(--darkGray);
    transition: all 250ms ease-in-out;
    text-decoration: none;
    color: transparent;
    outline: none;
    appearance: none;
    &::before {
      display: block;
      height: 200%;
      width: 200%;
      margin-left: -50%;
      margin-top: -50%;
      content: "";
      transform: rotate(45deg); } }
  .react-datepicker__navigation--previous {
    transform: rotate(-45deg);
    left: 16px;
    top: 17px; }
  .react-datepicker__navigation--next {
    transform: rotate(135deg);
    top: 17px; }
  .react-datepicker__day--range-start {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%; }
  .react-datepicker__day--range-end {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%; } }
@media screen and ( max-width: 1366px ) {
  .DatePicker {
    .react-datepicker__month-container {
      &:last-child {
        border-left: 0; } } } }
