.OptionsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .Field {
    margin: 24px 0px; }
  .Field, input {
    border-radius: 16px; }
  label {
    left: 44px; }
  .Field_with_value {
    label {
      left: 16px; } } }
.EmptyText {
  text-align: center;
  color: var(--darkGray); }
.OptionTile {
  display: grid;
  grid-template-columns: 180px 1fr max-content;
  grid-gap: 16px;
  cursor: pointer;
  padding-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px var(--lightGray) solid; }

  .PublishTag {
    font-size: 12px;
    padding: 4px 8px;
    background-color: var(--failColor);
    color: var(--notyColor);
    border-radius: 4px; }
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-content: start;
    .Title {
      font-size: 18px; }
    .Status {
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 12px; }
    .Published {
      background-color: var(--successColor);
      color: var(--accent); }
    .NotPublished {
      background-color: var(--failColor); } }
  .Options {
    display: flex;
    .OptionButton {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 16px; } }
    .Accent {
      background-color: var(--accent);
      color: #fff; }
    .Red {
      background-color: var(--accent2);
      color: #fff; } } }

@media screen and ( max-width:  576px) {
  .OptionTile {
    grid-template-columns: 1fr;
    .Options {
      justify-content: center; } } }
