.Nav {
  width: 64px;
  height: 100vh;
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 16px 0px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
.NavBody {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }
.NavElement {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  justify-items: center;
  cursor: pointer;
  .NavElement-Icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: var(--darkGray);
    font-size: 18px; }
  .Title {
    color: var(--black);
    font-weight: 500; }
  &:hover {
    .NavElement-Icon {
      background-color: var(--accent);
      color: #fff; } } }
.NavElement-Active {
  .NavElement-Icon {
    background-color: var(--accent);
    color: #fff; } }

@media screen and ( max-width: 576px ) {
  .Nav {
    width: 100vw;
    height: 64px;
    top: auto;
    bottom: 0;
    flex-direction: row;
    padding: 0px 16px; }
  .NavBody {
    grid-template-columns: repeat(3, max-content);
    grid-gap: 24px;
    align-content: center; }
  .NavElement {
    grid-gap: 4px;
    align-content: center;
    .NavElement-Icon {
      width: 24px;
      height: 24px; }
    &:hover {
      .NavElement-Icon {
        background: none;
        color: var(--accent); } }
    .Title {
      font-size: 12px; } }
  .NavElement-Active {
    .NavElement-Icon {
      background: none;
      color: var(--accent); } } }
