.OrderDetails-Content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 48px;
  .ModuleSpinner-Container {
    width: 100%;
    grid-column: 1/-1; }
  .Order {}
  .Client {}
  .Title {
    font-size: 18px;
    font-weight: 500; }
  .Passport {
    margin-top: 16px; } }
.Buttons {
  grid-column: 1/-1; }
.OrderDetails-Buttons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px; }

@media screen and ( max-width: 576px ) {
  .OrderDetails-Content {
    grid-template-columns: 1fr; } }
