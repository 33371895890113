.OrderManager-Content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 48px;
  .ModuleSpinner-Container {
    width: 100%;
    grid-column: 1/-1; }
  .StatusMessage {
    grid-column: 1/-1; }
  .Client {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;
    align-content: start; }
  .Title {
    font-size: 18px;
    font-weight: 500;
    grid-column: 1/-1; }
  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } }
  .OrderManager-Tech {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 48px;
    align-content: start;
    .DatePicker {
      grid-column: 1/-1;
      width: 100%;
      display: flex;
      justify-content: center; }
    .SubTitle {
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 600;
      margin-bottom: 8px; }
    .Locations {
      grid-column: 1/-1;
      display: grid;
      grid-gap: 48px; }
    .AdditionalOptions {
      grid-column: 1/-1;
      .Fieldset {
        display: grid;
        grid-template-columns: 1fr 16px;
        width: 100%;
        grid-gap: 32px; } }
    .PaymentType, .Counters {
      grid-column: 1/-1; } } }

@media screen and ( max-width: 576px ) {
  .OrderManager-Content {
    grid-template-columns: 1fr; } }
